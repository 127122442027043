import { Link, useParams } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { IsGranted } from '../../../app/modules/auth/PrivateRoute'
import { ROLE, useAuth } from '../../../app/modules/auth'
import { IClientItem } from '../../../app/modules/clients/core/_models'
import axios from 'axios'
import { API_URL } from '../../../constants'
import { Dispatch, SetStateAction, useState } from 'react'
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap'

export const ClientItemRequisite = (props: {
  client: IClientItem
  setClient?: (client: IClientItem) => void
  clients: IClientItem[]
  setClients: Dispatch<SetStateAction<IClientItem[]>>
  chooseClient: boolean
  showFlagColumn?: boolean
  active?: boolean
  fetching: boolean
  setFetching: Dispatch<SetStateAction<boolean>>
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const client = props.client
  const { currentUser } = useAuth()
  const params = useParams()

  const addClientToRequisite = async () => {
    try {
      props.setFetching(true)

      if (!props.fetching) {
        setLoading(true)
        if (props.clients.map((el) => el.token).includes(client.token)) {
          Swal.fire('Клиент уже находится в списке активных').then(() => {
            setLoading(false)
          })
        } else {
          const response = await axios.post(
            `${API_URL}/api/internal/requisite/${params.id}/set-clients`,
            {
              clientList: [...props.clients.map((el) => el.token), client.token],
            }
          )
          if (response.status === 200) {
            const newClientRes = await axios.get(
              `${API_URL}/api/internal/external_user/${client.token}`
            )
            if (props.setClient) {
              props?.setClients((prev) => [newClientRes.data.externalUser, ...prev])
            }
            setLoading(false)
          }
        }
      }
      props.setFetching(false)
    } catch (error) {
      Swal.fire('Ошибка отправки запроса. Попробуйте позже')
    }
  }

  const deleteClientFromRequisite = async () => {
    props.setFetching(true)

    try {
      if (!props.fetching) {
        setLoading(true)
        const response = await axios.post(
          `${API_URL}/api/internal/requisite/${params.id}/set-clients`,
          {
            clientList: props.clients
              .filter((el) => el.token !== client.token)
              .map((el) => el.token),
          }
        )
        if (response.status === 200) {
          props.setClients((prev) => prev.filter((el) => el.token !== client.token))
          setLoading(false)
        }
      }
      props.setFetching(false)
    } catch (error) {
      Swal.fire('Ошибка отправки запроса. Попробуйте позже')
    }
  }

  return (
    <>
      <tr>
        <td>
          {
            <img
              src={toAbsoluteUrl(`/media/flags/${client.options.color}.svg`)}
              alt=''
              className='w-30px'
            />
          }
        </td>
        <td>
          {IsGranted(
            [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR],
            currentUser
          ) ? (
            <Link to={'/client/info/' + client.token} className='text-primary' target='_blank'>
              {client.externalId}
            </Link>
          ) : (
            client.externalId
          )}
        </td>
        {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
          <>
            <td>
              <div>{client.merchant.title}</div>
            </td>
            <td className='min-w-350px text-break'>{client.comment}</td>
          </>
        )}

        <td>
          {client.lastActivityDate
            ? format(parseISO(client.lastActivityDate), 'dd.MM.yyyy HH:mm')
            : ''}
        </td>
        <td>{format(parseISO(client.createdAt), 'dd.MM.yyyy HH:mm')}</td>
        {props.chooseClient ? (
          <td className='float-right'>
            <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-4'>
              <KTIcon className='fs-1' iconName='check-square' />
            </button>
          </td>
        ) : (
          ''
        )}
        {loading ? (
          <td>
            <Spinner />
          </td>
        ) : (
          <td
            className='cursor-pointer'
            onClick={props.active ? deleteClientFromRequisite : addClientToRequisite}
          >
            <KTIcon
              className={`fs-1 ${props.active ? 'text-danger' : 'text-success'}`}
              iconName={props.active ? 'cross-circle' : 'down-square'}
            />
          </td>
        )}
      </tr>
    </>
  )
}
