import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { API_URL } from '../../../constants'
import { IRequisite } from '../../../types'
import { SelectStyles } from '../../../components/Select'
import { useThemeMode } from '../../../_metronic/partials'

const RequisiteMultipleFilter = ({ changeSelect }: { changeSelect: any }) => {
  const { mode: theme } = useThemeMode()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [requisites, setRequisites] = useState()

  useEffect(() => {
    axios.get(`${API_URL}/api/internal/requisite/list/priority`).then((res) => {
      setRequisites(
        res.data.requisites.map((el: IRequisite) => ({
          ...el,
          value: el.name,
          label: el.name.toLowerCase(),
        }))
      )
      setIsLoading(false)
    })
  }, [])

  return isLoading ? (
    <>Загрузка мерчантов...</>
  ) : (
    <Select
      onChange={(data) => {
        changeSelect({ type: 'requisites', value: data })
      }}
      isMulti={true}
      options={requisites}
      placeholder={'Реквизит'}
      inputValue={''}
      styles={SelectStyles(theme)}
    />
  )
}

export default RequisiteMultipleFilter
