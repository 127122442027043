import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getListRequisites, getRequisitesTotalSum } from '../../core/_requests'
import RequisiteItem from './components/RequisiteItem/RequisiteItem'
import { Table } from '../../../../../components/Table'
import { TableHead } from '../../../../../components/Table/TableHead'
import { TableBody } from '../../../../../components/Table/TableBody'
import { ROLE, useAuth } from '../../../auth'
import { DEFAULT_REQUISITE_LIST } from '../../../../../constants'
import CashBoxFilter from '../../../../common/filter/CashBoxFilter'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Доступные для резервирования',
    path: '/settings/requisites',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER],
  },
  {
    title: 'Недоступные для резервирования',
    path: '/settings/disabledrequisites',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER],
  },
]

const ListRequisites = ({ reservationDisabled }: { reservationDisabled: boolean }) => {
  const { currentUser } = useAuth()
  const [requisiteList, setRequisiteList] = useState(DEFAULT_REQUISITE_LIST)
  const [errorAPI, setErrorAPI] = useState('')
  const [requisitesSum, setRequisitesSum] = useState(0)
  const [cashBoxId, setCashBoxId] = useState<number[]>([])
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [searchDebounce, setSearchDebounce] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  const getRequisitesList = async () => {
    setIsLoading(true)
    let response = await getListRequisites({
      cashBoxId,
      disableReservation: reservationDisabled,
      accountNumber: searchInputRef.current?.value,
    })

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.requisites) {
      setRequisiteList(response.data.requisites)
      setIsLoading(false)
      setErrorAPI('')
    } else if (response.data.errors) {
      setRequisiteList([])

      setErrorAPI('Возникла ошибка: ' + JSON.stringify(response.data.errors))
    }
  }

  const updateRequisitesSum = async () => {
    let response = await getRequisitesTotalSum()

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.sum) {
      setRequisitesSum(response.data.sum)

      setErrorAPI('')
    } else if (response.data.errors) {
      setErrorAPI('Возникла ошибка: ' + JSON.stringify(response.data.errors))
    }
  }

  useEffect(() => {
    getRequisitesList()

    if (
      currentUser?.roles.some((el: any) => [ROLE.ROLE_ADMIN, ROLE.ROLE_SENIOR_CASHIER].includes(el))
    ) {
      updateRequisitesSum()
    }
  }, [cashBoxId, reservationDisabled])

  const requisiteItemCallback = () => {
    getRequisitesList()
    updateRequisitesSum()
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchDebounce)
    const newTimer = setTimeout(() => getRequisitesList(), 500)
    setSearchDebounce(newTimer)
  }

  return (
    <div className='card'>
      <PageTitle breadcrumbs={breadCrumbs}>Реквизиты</PageTitle>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Реквизиты</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Количество: {requisiteList.length}
          </span>
          {currentUser?.roles.some((el: any) =>
            [ROLE.ROLE_ADMIN, ROLE.ROLE_SENIOR_CASHIER].includes(el)
          ) ? (
            <span className='mt-1 fw-semibold fs-7'>Общий баланс: {requisitesSum}</span>
          ) : (
            ''
          )}
        </h3>

        <div className='col-lg-4 ms-auto me-4 align-self-center'>
          <div className='position-relative me-md-2'>
            <span className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 start-0 translate-middle ms-5'>
              <i className='fa fa-search'></i>
            </span>
            <input
              ref={searchInputRef}
              type='text'
              className='form-control form-control-solid ps-10 pt-3 pb-2'
              onChange={onSearchChange}
              placeholder='Поиск по "номер счета"'
            />
          </div>
        </div>

        {currentUser?.roles.some((el: any) =>
          [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER].includes(el)
        ) && (
          <div className='col-lg-4 ms-auto me-4 align-self-center'>
            <CashBoxFilter
              value={cashBoxId}
              changeSelect={(selected) => setCashBoxId(selected.value)}
            />
          </div>
        )}

        <a
          href='/settings/requisite-add'
          className='btn btn-primary px-6 align-self-center text-nowrap'
        >
          Создать новый реквизит
        </a>
      </div>
      <div className='card-body py-3'>
        <Table>
          <TableHead>
            <tr>
              <th className='min-w-50px'>ID</th>
              <th className='min-w-50px'>Название</th>
              <th className='min-w-150px'>Тип/Платежный метод</th>
              <th className='min-w-50px'>Касса</th>
              <th className='min-w-250px'>Данные</th>
              <th className='min-w-150px'>Номер счета</th>
              <th className='min-w-120px'>Баланс</th>
              <th className='min-w-120px'>Лимит пополнений</th>
              <th className='min-w-120px'>Лимит по тикетам</th>
              <th className='min-w-120px text-center'>Статус</th>
              <th className='min-w-100px'>Комментарий</th>
              <th className='min-w-50px'></th>
            </tr>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <tr>
                <td colSpan={11}>Загрузка...</td>
              </tr>
            ) : (
              requisiteList.map((requisite, index) => (
                <RequisiteItem
                  requisite={requisite}
                  key={index}
                  requisiteItemCallback={requisiteItemCallback}
                />
              ))
            )}
            <tr>{errorAPI ? errorAPI : <></>}</tr>
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default ListRequisites
