import { Dispatch, SetStateAction, useState } from 'react'
import { TableHead } from '../../Table/TableHead'
import { TableBody } from '../../Table/TableBody'
import { Table } from '../../Table'
import { ClientItemRequisite } from '../ClientItemRequisite'
import { IClientItem } from '../../../app/modules/clients/core/_models'

const AssignedClients = ({
  clients,
  setClients,
  fetching,
  setFetching,
}: {
  clients: IClientItem[]
  setClients: Dispatch<SetStateAction<IClientItem[]>>
  fetching: boolean
  setFetching: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div className='card mt-10'>
      <div className='py-3'>
        {clients.length === 0 ? (
          <tr>
            <td>Отсутствуют клиенты связанные с реквизитом</td>
          </tr>
        ) : (
          <Table>
            <TableHead>
              <tr>
                {<th className='min-w-120px'>flag</th>}
                <th className='min-w-120px'>external user id</th>
                <>
                  <th className='min-w-150px'>мерчант</th>
                  <th className='min-w-250px'>комментарий</th>
                </>
                <th className='min-w-150px'>последняя активность</th>
                <th className='min-w-150px'>дата создания</th>
                <th className='min-w-60px'>удалить</th>
              </tr>
            </TableHead>
            <TableBody>
              {clients.length > 0 ? (
                clients.map((el) => (
                  <ClientItemRequisite
                    client={el}
                    chooseClient={false}
                    active={true}
                    clients={clients}
                    setClients={setClients}
                    fetching={fetching}
                    setFetching={setFetching}
                  />
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default AssignedClients
