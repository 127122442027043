import { useEffect, useState } from 'react'
import { ClientsSearchRequisite } from '../../../../../components/ClientsSearchRequisite'
import AssignedClients from '../../../../../components/ClientsSearchRequisite/AssignedClients/AssignedClients'
import axios from 'axios'
import { API_URL } from '../../../../../constants'
import { useParams } from 'react-router-dom'
import { IClientItem } from '../../../clients/core/_models'

const ResuisiteClients = () => {
  const [clients, setClients] = useState<IClientItem[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const param = useParams()

  useEffect(() => {
    axios.get(`${API_URL}/api/internal/external_user/list?requisiteId=${param.id}`).then((res) => {
      setClients(res?.data?.externalUsers || [])
    })
  }, [])

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Клиенты</span>
          </h3>
        </div>
      </div>

      <div className='card'>
        <div className='card-body py-3'>
          <ClientsSearchRequisite
            activeClients={clients}
            setActiveClients={setClients}
            fetching={fetching}
            setFetching={setFetching}
          />
        </div>
      </div>
      <h3 className='card-label fw-bold fs-3 mt-10'>
        <span>Клиенты связанные с реквизитом</span>
      </h3>
      <div className='card mt-5'>
        <div className='card-body py-3'>
          <AssignedClients
            clients={clients}
            setClients={setClients}
            fetching={fetching}
            setFetching={setFetching}
          />
        </div>
      </div>
    </div>
  )
}

export default ResuisiteClients
